new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.home-gallery__slides').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 4,
		prevArrow: "<button class='home-gallery__button home-gallery__button--prev' aria-label='Previous' type='button'><i class='fas fa-long-arrow-alt-left'></i></button>",
		nextArrow: "<button class='home-gallery__button home-gallery__button--next' aria-label='Previous' type='button'><i class='fas fa-long-arrow-alt-right'></i></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$(".toplinks__dropdown").each(function () {
		$(".toplinks__dropdown-link", this).each(function (index, value) {
			$(this).attr({ "data-wow-delay": index / 10 + "s", "data-wow-offset": 0 })
				.addClass("wow fadeIn");
		});
	});

	function fixHeights() {
		$('.testimonials .RotatorTestimonialContent').sameHeight();
		$('.featured-category').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});

});

